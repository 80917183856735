@import url('https://fonts.googleapis.com/css2?family=Caprasimo&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active-link {
  border-bottom: 2px solid #03002F;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Variable.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Variable.ttf") format("truetype"),
    url("./assets/fonts/Satoshi-Variable.woff") format("woff");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src: url("./assets//fonts/CabinetGrotesk-Variable.ttf") format("truetype");
  src: url("./assets/fonts/CabinetGrotesk_Complete/Fonts/WEB/fonts/CabinetGrotesk-Variable.woff2") format("woff2");
  src: url("./assets//fonts//CabinetGrotesk_Complete/Fonts//WEB//fonts/CabinetGrotesk-Variable.woff") format("woff");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}



.image-slider {
  position: relative;
  width: 100%;
  height: 100%; /* Adjust as needed */
  overflow: hidden;
}

.image-slider img {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

.image-slider img.active {
  opacity: 1;
}

html {
  scroll-behavior: smooth;
}